<template>
  <div class="form-elements">
    <div class="container">
      <vuestic-widget>
        <div>
          <form @submit.prevent="editarOucadastrar()">
            <!-- CRIAR CHECKLIST -->
            <div class="row">
              <div class="col">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        v-model="checklist.nome"
                        id="simple-input-id-checklist"
                      />
                      <label
                        class="control-label"
                        for="simple-input-id-checklist"
                      >Nome da Checklist
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="col-3">
                <button
                  :disabled="checklist.nome.length === 0"
                  class="btn btn-primary btn-micro"
                  @click.prevent="editarOucadastrar()"
                >
                  Salvar Checklist
                </button>
              </div>

            </div>
            <!-- Adicionar Area -->
            <div
              class="row"
              v-if="!creatChecklist"
            >

              <div class="col-9">
                <form @submit.prevent="cadastrarArea()">
                  <fieldset>
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          id="simple-input-id-area"
                          v-model="area_da_checklist.nome"
                          required
                        />
                        <label
                          class="control-label"
                          for="simple-input-id-area"
                        >Nome da Área da Checklist</label>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div class="col-3">
                <button
                  v-if="!area_da_checklist.id"
                  :disabled="area_da_checklist.nome.length === 0"
                  class="btn btn-info btn-micro"
                  @click.prevent.stop="cadastrarArea()"
                >
                  Adicionar Área
                </button>
                <span
                  class="ml-auto"
                  v-else
                >
                  <el-button
                    type="primary"
                    icon="el-icon-check"
                    circle
                    @click="editarArea"
                  />
                  <el-button
                    type="danger"
                    icon="el-icon-close"
                    circle
                    @click="clearArea"
                  />
                </span>
              </div>
            </div>
          </form>
          <collapse
            v-for="area in checklist.area_da_checklist"
            :data="area"
            label="nome"
            :key="area.id"
            @edit="area_da_checklist = {...$event}"
            @delete="modalDeletar"
          >
            <vuestic-widget class="p-0">
              <div class="d-flex justify-content-end mb-2">
                <button
                  @click="push('cadastrar-pergunta',{ area: area.id, checklist: idChecklist })"
                  class="btn btn-info btn-micro"
                >Adicionar pergunta</button>
              </div>
              <data-tables
                class="style-body-table"
                layout="table"
                :data="area.pergunta"
                :action-col="actionCol"
                :table-props="tableProps"
              >
                <el-table-column
                  prop="pergunta"
                  label="NOME"
                  sortable="custom"
                  min-width="100"
                />
                <el-table-column
                  prop="tipos_de_respostas"
                  label="Resposta"
                  sortable="custom"
                >
                  <template slot-scope="row">
                    <div>

                      <span v-if="row.row.tipos_de_respostas === 4">
                        <strong>

                          {{typeQuestionsMask[row.row.formatacao-1].nome}}
                        </strong>
                      </span>
                      <all
                        :naoSeAplica="row.row.nao_se_aplica"
                        :tipo="row.row.tipos_de_respostas"
                      />
                    </div>
                  </template>
                </el-table-column>
              </data-tables>
            </vuestic-widget>
          </collapse>
        </div>
        <br />
        <div class="row d-flex justify-content-end">
          <button
            class="btn btn-pale btn-micro mr-2"
            @click="push('lista-checklist')"
          >
            Voltar
          </button>
        </div>
      </vuestic-widget>
    </div>
    <vuestic-modal
      v-bind:small="true"
      v-bind:force="true"
      ref="modalDeletePergunta"
      :cancelClass="'none'"
      :okText="'Revomer' | translate"
      v-on:ok="deletarPergunta(perguntaChangeForDelete.id)"
    >
      <div slot="title">{{ 'Deletar Pegunta' | translate }}</div>
      <div>Você deseja deletar a pergunta
        <strong>"{{perguntaChangeForDelete.pergunta}}"</strong>?
      </div>
    </vuestic-modal>
    <vuestic-modal
      v-bind:small="true"
      v-bind:force="true"
      ref="modalDeleteArea"
      :cancelClass="'none'"
      :okText="'Revomer' | translate"
      v-on:ok="deleteArea(areaChengeForDelete.id)"
    >
      <div slot="title">{{ 'Deletar área' | translate }}</div>
      <div>Você deseja deletar a área <strong>"{{areaChengeForDelete.nome}}"</strong>?</div>
    </vuestic-modal>
    <vuestic-modal
      v-bind:small="true"
      v-bind:force="true"
      ref="staticModalDelete"
      :cancelClass="'none'"
      :okText="'Revomer' | translate"
      v-on:ok="deletarArea(idArea)"
    >
      <div slot="title">{{ 'Deletar Área da Checklist' | translate }}</div>
      <div>Você deseja deletar essa Área?</div>
    </vuestic-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import collapse from '@/components/my-components/form/collapse.vue';
import all from '../../../vuestic-theme/vuestic-components/vuestic-tipo_de_resposta/todasAsRespostas.vue';

export default {
  name: 'cadastrar-checklist',
  components: {
    all,
    collapse,
  },
  props: {
    routerSuffix: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      show: false,
      idChecklist: null,
      idDaPergunta: null,
      idArea: null,
      areaComPerguntas: [],
      perguntaChangeForDelete: {},
      areaChengeForDelete: {},
      perguntas: [],
      tableProps: {
        stripe: true,
      },
      actionCol: {
        label: 'EDITAR / EXCLUIR',
        props: {
          align: 'center',
          width: '125',
        },
        buttons: [
          {
            props: {
              circle: true,
              type: 'primary',
              icon: 'el-icon-edit',
            },
            handler: (row) => {
              this.push('editar-pergunta', { checklist: this.idChecklist, id: row.id });
            },
          },
          {
            props: {
              type: 'danger',
              icon: 'el-icon-delete',
              circle: true,
            },
            handler: (row) => {
              this.idDaPergunta = row.id;
              this.showStaticModal();
            },
          },
        ],
      },
      area_da_checklist: {
        nome: '',
        pergunta: [],
      },
      checklist: {
        nome: '',
        area_da_checklist: [],
      },
    };
  },
  created() {
    this.init();
  },

  methods: {
    ...mapActions('checklist', [
      'getChecklistFullById',
      'postChecklist',
      'putChecklist',
    ]),
    ...mapActions('areaDaChecklist', [
      'postArea',
      'putArea',
      'deleteArea',
    ]),
    ...mapActions('pergunta', [
      'deletePergunta',
    ]),
    ...mapActions('acesso', ['postAcesso']),

    init() {
      this.idChecklist = this.$router.history.current.params.checklist;
      this.verificarCadastroOuEdicao();
    },

    async getChecklist() {
      try {
        this.checklist = await this.getChecklistFullById({ id: this.idChecklist });
      } catch (error) {
        console.error(error);
      }
    },
    push(name, params) {
      this.$router.push({
        name: `${name}${this.routerSuffix}`,
        params,
      });
    },
    showStaticModal() {
      this.$refs.staticModal.open();
    },
    modalDeletar(event) {
      this.$refs.staticModalDelete.open();
      this.idArea = event.id;
    },
    editarOucadastrar() {
      if (this.idChecklist === undefined) {
        this.cadastrarChecklist();
      } else {
        this.editarChecklist();
      }
    },
    verificarCadastroOuEdicao() {
      if (this.idChecklist) {
        this.getChecklist();
      }
    },

    async cadastrarArea() {
      try {
        const res = await this.postArea(this.area_da_checklist);
        await this.addAreaInChecklist(res.id);
        this.checklist.area_da_checklist.push({
          id: res.id,
          nome: this.area_da_checklist.nome,
          pergunta: [],
        });
        this.clearArea();
      } catch (error) {
        console.error(error);
      }
    },
    async addAreaInChecklist(idArea) {
      try {
        const idsArea = this.checklist.area_da_checklist.map(a => a.id);
        idsArea.push(idArea);
        const checklist = {
          id: this.idChecklist,
          nome: this.checklist.nome,
          area_da_checklist: idsArea,
        };
        const res = await this.putChecklist(checklist);
        return res;
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    async cadastrarChecklist() {
      try {
        const user = JSON.parse(localStorage.user);
        const { id } = user.funcionario || false;
        const res = await this.postChecklist(this.checklist);
        this.idChecklist = res.id;
        this.checklist = res;
        if (id) {
          const { empresaSelecionada } = localStorage;
          await this.postAcesso(
            { empresa: empresaSelecionada, funcionario: [id], checklist: res.id },
          );
        }
        this.push('cadastrar-checklist-id', { checklist: res.id });
      } catch (error) {
        console.error(error);
      }
    },
    async editarArea() {
      try {
        await this.putArea(this.area_da_checklist);
        const index = this.checklist.area_da_checklist.findIndex(
          a => a.id === this.area_da_checklist.id,
        );
        this.checklist.area_da_checklist[index].nome = this.area_da_checklist.nome;
        this.clearArea();
      } catch (error) {
        console.error(error);
      }
    },
    clearArea() {
      this.area_da_checklist = {
        nome: '',
        pergunta: [],
      };
    },
    async editarChecklist() {
      try {
        const { checklist } = this;
        checklist.area_da_checklist = this.checklist.area_da_checklist.map(a => a.id);
        await this.putChecklist(checklist);
      } catch (error) {
        console.error(error);
      }
    },
    async deletarArea(id) {
      try {
        const index = await this.checklist.area_da_checklist.findIndex(area => area.id === id);
        await this.deleteArea(id);
        this.checklist.area_da_checklist.splice(index, 1);
      } catch (error) {
        console.error(error);
      }
    },
    async deletarPergunta(id) {
      let indexArea;
      let indexPergunta;
      try {
        for (let indexA = 0; indexA < this.checklist.area_da_checklist.length; indexA += 1) {
          const area = this.checklist.area_da_checklist[indexA];
          const indexP = area.pergunta.findIndex(p => p.id === id);
          if (indexP > -1) {
            indexPergunta = indexP;
            indexArea = indexA;
            break;
          }
        }
        await this.deletePergunta(id);
        this.checklist.area_da_checklist[indexArea].pergunta.splice(indexPergunta, 1);
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
  computed: {
    ...mapGetters(['typeQuestionsMask']),
    creatChecklist() {
      const action = !this.idChecklist;
      return action;
    },
  },
};
</script>

<style>
.style-body-table {
	border: 1px solid #c2c2c2;
}
.cell .container .row .icons {
	width: 30px !important;
	height: 30px !important;
	margin: 0% !important;
}
.color-white {
	background: white;
}
strong {
	font-weight: 900;
}
</style>
